body {
  max-width: 100vw;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gm-style iframe + div { border:none !important; }
.gm-style > div:nth-child(3) { border:none !important; }

.arrow {

  cursor: pointer;
  margin-left: -34px;
  margin-top: -70px;
}
.arrow .MuiBadge-badge {
  border: none !important;
}
.arrow:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: rgba(26, 115, 232, 0.9) !important;
  border-bottom: 0;
  margin-left: -10px;
  margin-bottom: -9px;
}

.clusterMarker {
  background-color: rgb(255, 181, 0) !important;
  border-radius: 50%;
  border: 5px solid red !important;
  border-color: rgba(255, 181, 0, 0.5) !important;
  background-clip:padding-box;
  height: 40px;
  line-height: 40px;
  width: 40px;
  text-align: center;
  cursor: pointer;
  top: 20px;
  left: -20px;
  color: rgb(255, 181, 0) !important;
  position: absolute;
}
.clusterMarker-title {
  font-size: 22px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  margin-top: -5px;
  color: rgb(26, 115, 232);
}


.popover-place .show {
max-width: 500px;
width: 20vw;
  background-color: transparent;
max-height: 30vh;
height: 30vh;
}
.popover-place .arrow {
display: none !important;
}
.popover-place .MuiCard-root,.popover-place .MuiPaper-root {
  max-width: 350px;
  width: 20vw;
  min-width: 342px;
  max-height: 30vh;
  height: 350px;
}

.badge-card-marker>span {
  padding: 0 8px !important;
  height: 30px !important;
}

.master-card-wrapper .MuiCard-root {
  max-width: 500px;
  width: 100%;
  min-width: 342px;
  min-height: 342px;

  aspect-ratio: 20/19;
}

.master-card-wrapper .MuiAvatar-img {
 margin-top: 15% !important;
}

.bottom-line {
  width: calc(100% - 20px) !important
}
.master-card-wrapper .bottom-line {
  position: absolute !important;
  bottom: 10px;
  width: 100%;
}

.bottom-line.no2 {
  position: relative !important;
  margin-top: calc(67% + 40px);
  width: 100%;
  height: calc(100% - 270px);
}

.card-master-2:hover .bottom-line.no2 {
  position: relative !important;
  margin-top: calc(67% + 40px);
  width: 100%;
  height: calc(100% - 210px);
}

.card-desc {
  text-overflow: ellipsis;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  line-height: 1.15 !important;
  -webkit-box-orient: vertical;
  color: #565175 !important;
}

.card-master-2:hover .card-desc, .card-master-2.selected .card-desc {
  -webkit-line-clamp: 4;
  margin-top: calc(-33% + 10px);
  height: 8rem;
}
@media (max-width: 400px) {
  .card-master-2:hover .card-desc, .card-master-2.selected .card-desc {
    height: 6rem;
  }
}

.master-card-wrapper .card-header {
  border-radius: 0.75rem !important;
  border-bottom: none !important;
}
@media (min-width: 992px) {
.master-card-wrapper.col-lg-6 {
  max-width: 49%;
}
.master-card-wrapper.col-lg-6 .MuiCard-root {
  min-width: 322px;
}
.master-card-wrapper.col-lg-6 .bottom-line {
  min-width: 290px;
}
}
.master-card-wrapper .text-nowrap {
  max-width: 75% !important;
}


.main-navbar .navbar-brand {
  /* SpotYa */

  margin: 0 auto !important;;

  font-family: 'Karla', serif !important;;
  font-style: normal !important;;
  font-weight: 700 !important;;
  font-size: 40px !important;;
  line-height: 47px !important;;
  /* identical to box height */

  /* purple */
  color: #7416B4 !important;

  text-transform: none!important;
}
.main-navbar .container {
  padding: 0 !important;
}

.navbar-translate-collapse .nav-link {
  font-size: 18px !important;
}
.navbar-translate-collapse .nav-link svg {
  margin-top: -4px;
}

.form-check-sign::after, .form-check-sign::before {
  width: 18px !important;
  height: 18px !important;
  margin-top: 3px;
}
.form-check .form-check-sign::after {
  font-size: 12px !important;
  top: -4px !important;
}


.col-form-label-sm {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.container-search {
  max-width: 1280px !important;
  margin-bottom: 30px;
}

@media (max-width: 576px) and (min-width: 410px) {
  .container-search {
   padding: 0 10% !important;
  }
}

.noUi-connect {
  background: #B6ABF9 !important;
}

.map-wrapper {
  height: 40vh;
  width: 100%;
  margin-top: 87px;
}
.main-navbar {
  max-width: 100vw;
  /* Header */

  /* Auto layout */
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
  padding: 0 108px !important;
  gap: 245px !important;
  isolation: isolate !important;

  height: 90px !important;

  /* white */
  background: #FFFFFF !important;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;

}



.navbar-translate .navbar-toggler {
  /* Frame */

  margin: 0 auto;
  width: 32px;
  height: 32px;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.navbar-translate .navbar-toggler .navbar-toggler-bar {
  border-top: 1.5px solid #0D0314 !important;
  border-radius: 0px;
  width: 32px !important;
}
.navbar-translate .navbar-toggler .navbar-toggler-bar:not(:last-child) {
  margin-bottom: 8px !important;
}

.nav-item svg {
  display: none;
}
.nav-item .nav-lang-indicator svg {
  display: block;
}
.navbar-translate-collapse.collapse.navbar-collapse {
  justify-content: space-between;
}
.navbar-translate { width: 33% }

.profile-header-container {
  align-self: center;
  display: none;
}

.authorized .profile-header-container {
  align-self: center;
  display: none;
}

.authorized .profile-header-container.profile-header-container-auth {
  display: block !important;
}

.MuiPaper-root:has(.nav-link) {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.3) !important;
}


@media (max-width: 1100px) {
  .collapse:not(.show) {
    display: none !important;
  }
  .navbar-translate { width: 25% }
}
@media (max-width: 991px) {


  .nav-item {
    position: relative;
  }
  .navbar-translate { width: 100% }
  .nav-open .navbar-collapse .navbar-nav > .nav-item > .nav-link {
    /* Body Text/16 r */
    font-family: 'Karla' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    /* identical to box height, or 150% */
    letter-spacing: 0.02em !important;
    text-transform: none !important;

    /* dark */
    color: #0D0314 !important;
  }
  .nav-open .navbar-collapse .navbar-nav > .nav-item > .nav-link.active {
    color: white !important;
  }
  .nav-item svg {
    display: inline-block;
    position: absolute;
    right: 28px;
    top: 28px;
  }
  .nav-item:has(.nav-link.active) svg path {
    stroke: white !important;
  }
  .main-navbar {
    height: 64px !important;
    padding: 4px 16px !important;
  }
  .main-navbar .navbar-translate {
    height: 64px;
    display: flex;
  }
  .main-navbar .navbar-brand {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 24px !important;
    margin-top: -8px !important
  }
  .map-wrapper {
    height: 35vh;
    margin-top: 64px;
  }
  .navbar-translate .navbar-toggler {
    margin: 0 !important;
    margin-right: 16px !important;
  }

  .nav-open #root:after {
    content: ' ';
    position: absolute;
    top:0;
    left:0;
    z-index: 1000;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.25);
  }
  html:not(:has(.authorized)) .nav-lang {
    position: absolute;
    top: -117px;
    margin-left: 16px;
    width: calc(100% - 32px) !important;
  }
  .authorized .auth-none {
    display: none !important;
  }
  .nav-open  .navbar-collapse.show .nav-item.nav-search-combobox {
    margin-top: 48px;
    padding: 0 !important;
  }

  .nav-open .main-navbar {

    background-color: rgba(0,0,0,0.00125) !important;
  }

  .navbar-collapse.navbar-translate-collapse {
    /* wrapper */
    margin-top: 8px !important;
    /* Auto layout */
    padding: 8px 8px 24px !important;
    gap: 8px !important;

    width: 359px !important;
    height: 402px !important;

    /* white */
    background: #FFFFFF !important;
    border-radius: 24px !important;

    /* Inside auto layout */
    flex: none !important;
    order: 0 !important;
    align-self: stretch !important;
    flex-grow: 0 !important;

  }
  .navbar-translate.toggled .navbar-toggler {
    display: none;
  }

  .navbar-collapse.show .nav-item {
    margin-bottom: 8px;
    height: 48px !important;
    padding: 12px 16px 12px 16px !important;
  }

  .nav-open .login-nav {
    margin-top: -42px !important;
    text-align: center !important;
  }
  .nav-open .login-nav.nav-link > span {
    text-align: center !important;
    color: white !important;
    margin: 0 auto;
    text-transform: none !important;
  }

  .nav-open .nav-lang {
    margin-top: 84px !important;
    width: 100% !important;
  }
  .nav-open .nav-lang svg {
    margin-top: -8px !important;
    margin-right: -8px !important;
  }



  .authorized .navbar-collapse.navbar-translate-collapse {
    height: auto !important;
    position: absolute;
    top: 8px;
    left: auto;
  }

  .authorized .profile-header-container.profile-header-container-auth {
    display: none !important;
  }

  .nav-open .authorized .profile-header-container {
    width: 100%;
    display: block;
  }
  .nav-open .authorized .profile-header-container-auth {
    display: none;
  }
  .nav-open .authorized .profile-header-container .nav-link {
    margin-top: 8px !important;
    width: 100%;
  }
  .nav-open .authorized .navbar-nav:not(:has(.profile-header-container)) .nav-item  {

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .nav-open .authorized .navbar-nav:not(:has(.profile-header-container)) .nav-link  {

    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .nav-open .authorized .profile-header-container .navbar-icon-btn {
    width: calc(100% + 18px);
    margin-left: -3px !important;
    height: 60px;
    margin-bottom: 16px;
  }
  .nav-open .authorized .nav-lang {
    margin-top: 0 !important;
  }


}

.text-event-desc {
  font-size: 0.85rem !important;
}

.trim-vertical {
  padding-bottom: 1px;
  text-overflow: ellipsis;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.trim1-vertical:after {
  content: "\02026";
  position: absolute;
  width: 50%;
  right: 4px;
  text-align: right;
  vertical-align: bottom;
  background: inherit;
}

.trim-vertical-2 {
  max-height: 1.5rem !important;
  -webkit-line-clamp: 2 !important;
}
.trim1-vertical-2:after {
  top: 0.85rem;
}

.trim-vertical-3 {
  max-height: 2.5rem !important;
  -webkit-line-clamp: 3 !important;
}


.cta-master-2 {
  height: 0 !important;
  transition: opacity 0.5s ease-out;
  opacity: 0 !important;
  z-index: 100000;
  margin-top: auto !important;
}
.cta-master-2-container {
  position: absolute;
  bottom: 120px;
  width: 0;
}
.card-master-2:hover .cta-master-2-container, .card-master-2.selected .cta-master-2-container {
  position: absolute;
  bottom: 12px;
  width: calc(100% - 33px);
}
.card-master-2:hover .cta-master-2, .card-master-2.selected .cta-master-2 {
  opacity: 1 !important;
  height: auto !important;
  margin: auto;
}
 .card-header-backdrop {
   box-shadow: 0 0 50px 30px #B6ABF9 !important;
   border-radius: 0.75rem !important;
   opacity: 0 !important;
   pointer-events: none;
}

 .card-header-backdrop-image {
   box-shadow: 0 -30px 50px 0 white !important;
   border-radius: 0.75rem !important;
   opacity: 0 !important;
}

.card-master-2:hover .card-header-backdrop, .card-master-2.selected .card-header-backdrop {
  opacity: 0 !important;
  z-index: 100 !important;
}

.card-master-2:hover:not(>.top-card) .card-header-backdrop-image {
  opacity: 1 !important;
  z-index: -1 !important;
}

.card-master-2:hover:not(>.top-card) .card-header-backdrop-image, .card-master-2.selected:not(>.top-card) .card-header-backdrop-image {
  opacity: 1 !important;
  z-index: -1 !important;
}

.only-selected{
  display: none;
}
.card-master-2:hover .only-selected,  .card-master-2.selected .only-selected{
  display: block !important;
}

.row:has(.card-master-2:hover) .master-card-wrapper:not(:has(.card-master-2:hover)) {
  opacity: 0.5;
}


@media (max-width: 480px) {
  .ScrollbarsCustom-Wrapper:has(.card-master-2.top-card:hover), .ScrollbarsCustom-Wrapper:has(.card-master-2.top-card.selected) {
    top: -10px !important;
  }

  .ScrollbarsCustom-Content:has(.card-master-2.top-card:hover), .ScrollbarsCustom-Content:has(.card-master-2.top-card.selected) {
    padding-top: 20px !important;
  }
}
@media (min-width: 480px) {
  .ScrollbarsCustom-Wrapper:has(.card-master-2.top-card:hover), .ScrollbarsCustom-Wrapper:has(.card-master-2.top-card.selected) {
    top: -300px !important;
  }

  .ScrollbarsCustom-Content:has(.card-master-2.top-card:hover), .ScrollbarsCustom-Content:has(.card-master-2.top-card.selected) {
    padding-top: 300px !important;
  }
}

.pt-6 {
  padding-top: 52px !important;
}

.card-master-2 .MuiAvatar-rounded {
  border-radius: 0.75rem !important;
}
.card-master-2:hover .MuiAvatar-rounded:before {
  content: 'asdasdas';
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 0.75rem !important;
  z-index: -1 !important;
}



.fc-timegrid-slot-label-frame {
  text-align: center !important;
}

.slot-label {
  font-size: 1rem !important;
  line-height: 1 !important;
}
.fc-timegrid-slot-label {
  width: 8rem !important;
  min-width: 150px !important;
}


@media (min-width: 1200px) {
  col1 {
    width: 1rem !important;
  }
  .slot-label {
    margin-right: 10px;
  }
}
@media (max-width: 1200px) {
  .event-img {
    top: 18px !important;
  }
}
@media (max-width: 767px) {
  .event-img {
    top: 24px !important;
    right: calc(50% - 16px) !important;
  }
}

.fc-dayGridMonth-view .fc-daygrid-dot-event>div {
  width: 100%;
}
.fc-dayGridMonth-view .event-booking {
  max-height: 26px;
}
.fc-dayGridMonth-view .event-booking .MuiTypography-button {
  display: block;
  margin-top: -2px;
  margin-bottom: 5px;
  margin-left: 5px;
}.fc-dayGridMonth-view .event-booking .bottom-0 {
  margin-bottom: 1px;
}

.fc-timeGridWeek-view .event-booking {
  margin-top: -6px !important;
}

@media (max-width: 600px) {
  .event-img {
    top: 24px !important;
    right: calc(50% - 12px) !important;
  }
  .fc-dayGridMonth-view .event-img-month {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .MuiPaper-root .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 0.9rem !important;
  }
  .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion {
    padding: 0 !important;
  }
  col1 {
    width: 3rem !important;
  }

  .slot-label {
    width: 2.9rem !important;
    overflow: hidden !important;
  }
  .event-img {

  }
}
@media (max-width: 520px) {
  .MuiPaper-root .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 0.7rem !important;
  }
}
@media (max-width: 520px) {
  .MuiPaper-root .fc-toolbar-chunk .fc-button {
    font-size: 0.6rem !important;
  }
  .MuiPaper-root .fc-toolbar-chunk .fc-button[disabled] {
    font-size: 0.6rem !important;
  }
}

.fc-timegrid-event {
  min-width: 48px;
}

.fc-timeGridWeek-view .fc-scrollgrid-shrink-frame {
  min-height: 60px !important;
}

.fc .fc-timegrid-slot {

}


th.fc-day-today .fc-col-header-cell-cushion {
  color: -- !important;
}

.sign-layout .MuiButtonBase-root > .MuiSvgIcon-fontSizeLarge {
  font-size: 2rem !important;
}




.stepper-btn {
  z-index: 10 !important;
}
.stepper-btn .MuiSvgIcon-fontSizeInherit {
  font-size: 1.5rem !important;
}
.Mui-completed .stepper-btn .MuiSvgIcon-fontSizeInherit {
  color: #6bd098;
}


.MuiStepper-root.MuiStepper-vertical {
  width: max-content !important;
  padding: 10px;
}

.MuiStepper-root.MuiStepper-horizontal {
  width: 100% !important;
  padding: 10px;
}


.MuiStep-root .MuiStepLabel-label {
  margin-top: 2px !important;
}
.MuiStep-root .MuiStepLabel-label.Mui-active {
  font-weight: bold !important;
  color: white !important;
}
.Mui-completed .MuiStepLabel-label.Mui-completed {

}

.MuiStepConnector-lineVertical {
  margin-left: 11px;
  height: 100% !important;
}
.Mui-active .MuiStepConnector-lineVertical, .Mui-completed .MuiStepConnector-lineVertical {
  margin-left: 11px;
  height: 100% !important;
  opacity: 0.8 !important;
}

.flatpickr-day.today {
  background: rgba(26, 115, 232, 0.15) !important;
  color: rgba(26, 115, 232) !important;
}

.flatpickr-day.today.selected {
  color: white !important;
}


.fc-event.event-danger-dashed {
  background: rgba(255, 181, 0, 0.25) !important;
  border-style: dashed !important;
  border-width: 2px !important;
  border-color: #f5593d80 !important;
}
.fc-event.event-danger-dashed-new {
  background: rgba(255, 181, 0, 0.25) !important;
  border-style: dashed !important;
  border-width: 2px !important;
  border-color: #f5593d80 !important;
}
.fc-event.event-danger-dashed-new .label-box  {
  zoom: 1;
}

.fc-event-resizable.event-danger-dashed:before {
  content: '';
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  z-index: -1;
}
.fc-event-resizable.event-danger-dashed-new:before {
  content: '';
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  z-index: -1;
}

.fc .fc-timegrid-col.fc-day-today {
  background: rgba(255, 181, 0, 0);
}

.label-box {
  position: static;
  overflowX: hidden;
}

.fc-timegrid-col-events:has(.fc-timegrid-event-harness-inset[style*="z-index: 2;"])>div:nth-child(1) .label-box-saved {
  width: 150%;
  transform: scale(0.75) translate(-17.5%, -17.5%);
}
.fc-timegrid-col-events1:not(:only-child):has(:nth-child(3 of .fc-timegrid-event-harness-inset)) .fc-event .label-box {
  width: 300%;
  transform: scale(0.33, 0.5) translate(-100%, -50%);
}

.fc-timegrid-col-events1:not(:only-child):has(:nth-child(4 of .fc-timegrid-event-harness-inset)) .fc-event .label-box {
  width: 400%;
  transform: scale(0.25, 0.33) translate(-150%, -100%);
}

.fc-timegrid-event-harness-inset:has(.event-danger-dashed) {
  inset-block-end: 0 !important;
  writing-mode: vertical-rl;
}
.fc-timegrid-event-harness-inset:has(.event-danger-dashed) .fc-event {
  writing-mode: horizontal-tb;
}

.fc-timegrid-event-harness-inset[style*="z-index: 2;"]:has(.label-box-saved)::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -2;
  background: rgba(255, 255, 255, 1) !important;
}
.profile-calendar .fc-timegrid-event-harness-inset[style*="z-index: 3;"]:has(.label-box-saved)::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -2;
  background: rgba(255, 255, 255, 1) !important;
}
.profile-calendar .fc-theme-standard td.fc-timegrid-slot-lane {
  border-color: #bfc1c4 !important;
}

.fc-timegrid-event-harness-inset[style*="z-index: 2;"]:not(:has(.event-danger-dashed)) .fc-event .label-box-new {
  width: 137.5%;
  transform: scale(0.75) translate(-17%, -17%);
}
.fc-timegrid-event-harness-inset[style*="z-index: 2;"]:not(:has(.event-danger-dashed)) .fc-event .label-box {
  width: 200%;
  transform: scale(0.5) translate(-50%, -50%);
}
.fc-timegrid-event-harness-inset[style*="z-index: 3;"]:not(:has(.event-danger-dashed)) .fc-event .label-box {
  width: 300%;
  transform: scale(0.33, 0.5) translate(-100%, -50%);
}
.fc-timegrid-event-harness-inset[style*="z-index: 3;"]:not(:has(.event-danger-dashed)) .fc-event .label-box-new {
  width: 200%;
  transform: scale(0.5) translate(-50%, -50%);
}

.fc-timegrid-event-harness-inset[style*="z-index: 4;"]:not(:has(.event-danger-dashed)) .fc-event .label-box {
  width: 400%;
  transform: scale(0.25, 0.33) translate(-150%, -100%);
}



.fc-timegrid-event-harness-inset[style*="z-index: 2;"]:not(:has(.event-danger-dashed1)) .fc-event .label-box-saved {
  width: 133%;
  transform: scale(0.75) translate(-17.5%, -17.5%);
}
.fc-timegrid-event-harness-inset[style*="z-index: 3;"]:not(:has(.event-danger-dashed1)) .fc-event .label-box-saved {
  width: 200%;
  transform: scale(0.5, 0.5) translate(-50%, -50%);
}

.fc-timegrid-event-harness-inset[style*="z-index: 4;"]:not(:has(.event-danger-dashed1)) .fc-event .label-box-saved {
  width: 400%;
  transform: scale(0.25, 0.33) translate(-150%, -100%);
}


.fc-timegrid-event-harness-inset[style*="z-index: 2;"]:not(:has(.event-danger-dashed)) .fc-event .label-box-saved {
  width: 133%;
  transform: scale(0.75) translate(-17.5%, -17.5%);
}

.fc-list-event-time {
  width: 195px !important;
  min-width: 195px !important;
  text-align: center;
}

@media (max-width: 520px) {
  .fc-scroller1{
    width: 700vw !important;
  }
  .fc-scroller1{
    width: calc(100vw - 70px) !important;
    overflow: auto !important;
    display: block;
  }
  .fc-day1 {
    width: calc(100vw - 70px) !important;
  }
  .fc-list-event-time {
    display: inline-block;
    background: transparent !important;
    border: none !important;
  }
  .fc-list-event-title {
    border: none !important;
  }
  .fc-list-event {
    display: block;
  }
}

.event-overlay {
  border-radius: 0 0 3px 3px !important;
  background-color: rgba(255, 128, 0, 0.3) !important;
  border: none !important;
  color: white !important;
  z-index: 2 !important;
  opacity: 1 !important;
}

.event-session {
  background-color: rgba(40, 167, 69, 0.5);
  border-color: rgba(40, 167, 69, 0.75);
  color: white !important;
}

.event-session.fc-bg-event {
  border-radius: 3px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: rgba(40, 167, 69, 0.75) !important;
}

.fc-timegrid-cols:has(.label-box) .event-session {
  background-color: rgba(40, 167, 69, 0.25);
  opacity: 0.5;
  z-index: 0 !important;
  border-style: dotted !important;
  border-width: 2px !important;
}


.fc-timegrid-col-events:has(.label-box) .label-box-saved {
  color: rgba(52, 71, 103, 0.5) !important;
}

.MuiTooltip-tooltip {
  width: 300px
}

.mt-navbar {
  margin-top: 140px !important;
  #margin-top: 75px !important;
}

.mb-footer {
  margin-bottom: 75px !important;
}

.del-img-btn .MuiSvgIcon-fontSizeLarge {
  font-size: 1.25rem !important ;
}

.label-small {
  font-size: 1rem !important ;
}

.one-line {
  height: 1.4rem;
  width: 100%;
  overflow: hidden;
}

.booking-calendar-connector .MuiStepConnector-horizontal {
  top: 33%
}

.PhoneInputInput {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 4px;
  background-color: transparent;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  padding: 10px;
  border: 1px solid rgb(210, 214, 218);
}

.fc-list-event:hover td {
  background: unset !important;
  cursor: pointer;
}

.fc-list-event-graphic {
  display: none !important;
  border: none !important;
}

.event-tiny {
  max-height: 50%;
  margin-top: 29px;
}

.MuiSwitch-sizeLarge .MuiSwitch-thumb {
  width: 30px !important;
  height: 30px !important;
}

.MuiSwitch-sizeLarge .MuiSwitch-track {
  width: 3rem !important;
  height: 1.5rem !important;
  border-radius: 12px !important;
}

.MuiSwitch-sizeLarge {
  width: 87px !important;
  height: 57px !important;
  margin-bottom: -10px !important;
}
.border-bottom-025 {
  border-bottom-style: solid !important;
  border-bottom-color: #343a4080 !important;
  border-bottom-width: 0.25px !important;
}


.landing-page {

}

body:has(.landing-page) {
  overflow-x: hidden !important;
}

.landing-page .section {
  color: #A7A7A7;
  padding-top: 50px;
}

.landing-right {
  color: white !important;
}

.landing-page h6 {
  font-family: Montserrat, serif;
  font-size: 38px;
  font-weight: 400;
  line-height: 44px;
  text-align: left;
  color: #A7A7A7;
  text-transform: none;
}
.landing-page .landing-right h6 {
  text-align: right !important;
  color: white !important;
}

.landing-page .icon-box {
  position: relative;
  background: #212121;
  height: 106px;
  margin-bottom: 20px;
  display: grid;
}

.landing-page .landing-right .icon-box {
  position: relative;
  background: #000000;
}

.landing-page .icon-box::before {
  position: absolute;
  background: transparent;
  height: 100px;
  width: 99%;
  border-left: solid 1px #A7A7A7;
  border-bottom: solid 1px #A7A7A7;
  content: '';
  left: -5px;
  top: 11px;
}

.landing-page .icon-box .icon-box-label {
  position: absolute;
  left: 50px;
  justify-self: center;
  align-self: center;

  font-family: Montserrat, serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 25px;
  text-align: left;
  color: #A7A7A7;
  max-width: 50%;
  text-transform: uppercase;
}

.landing-page .landing-right .icon-box .icon-box-label {
  position: absolute;
  right: 50px;
  justify-self: right;
  align-self: center;
  color: white;
  text-transform: uppercase;
  text-align: right;
}

.landing-page .sub-icon-box {

  margin-bottom: 90px;
  font-family: Poppins, serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  min-height: 100px;
}

.landing-page .landing-right .sub-icon-box {
  text-align: right;
}

.landing-page .icon-box .icon-box-image {
  position: absolute;
  text-align: right;
  right: 60px;
  top: -42px;
}
.landing-page .landing-right .icon-box .icon-box-image {
  position: absolute;
  text-align: left;
  right: 320px;
  top: -42px;
  width: auto;
  max-width: 50%;
}

.profile-calendar .fc-timegrid-col-events {
  margin: 0 !important;
}
.fc-timegrid-col-events:has(.label-box-new) {
  margin: 0 !important;
}
.profile-calendar .fc-timegrid-event .fc-event-main {
  padding: 0 !important;
}

.profile-calendar .fc-event-main {
  width: 100% !important;
  overflow: hidden;
}

.fc-bg-event {
  width: 100% !important;
  overflow: hidden;
}

.fc-event-main >div, .fc-bg-event >div{
  display: grid;
  height: 100% !important;
  width: 100% !important;
}
.fc-timeGridWeek-view .MuiBox-root{
  min-height: 100% !important;
}
.fc-timeGridWeek-view .label-box-saved {
  padding-top: 0.33rem !important;
  height: 100% !important;
  cursor: pointer;
  max-width: 100% !important;
}

.label-box-saved .label-box-title {
  width: 100% !important;
  white-space: nowrap;
  overflow: hidden;
  font-size: 90% !important;
}
.label-box-schedule .label-box-title {
  font-size: 0.9rem !important;
}

.fc-daygrid-day {
  padding: 1px;
}
.fc-daygrid-day .fc-daygrid-day-frame {
  height: 60px !important;
  overflow: hidden;
}
.profile-calendar .fc-daygrid-day .fc-daygrid-day-frame .fc-daygrid-day-events {
  display: none;
}
.profile-calendar .fc-daygrid-day:hover .fc-daygrid-day-frame {
  margin: -1px;
  background: #3788d81E !important;
  border-style: dashed !important;
  border-width: 1px !important;
  border-color: #3788d8 !important;
  content: "select";
}

.profile-calendar .fc-event.event-danger-dashed::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(255, 255, 255, 1) !important;
}


.legend-button {
  width: 210px;
  margin: 0.25em !important;
}

@media (max-width: 520px) {
  .legend-button {
    width: 125px;
    padding: 6px !important;
    font-size: 10px !important;
  }

  .calendar-children-box {
    padding: 6px !important;
  }


  .event-session.fc-bg-event>div>.mb-n11:first-child {
    margin-top: -2px !important;
  }

  .event-session.fc-bg-event>div>.mb-n11 {
    margin-top: -1px !important;
  }


  .event-session.fc-event>div>div>.mb-n11:first-child {
    margin-top: -2px !important;
  }

  .event-session.fc-event>div>div>.mb-n11 {
    margin-top: -1px !important;
  }

}

.text-smaller {
  font-size: 50% !important;
}

.position-absolute.bottom-0 {
  bottom: 0 !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.fade:has(#viewerBackdrop) {
  opacity: 1 !important;
}

.text-white {
    color: white !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.form-wrapper .form-fieldset {
  display: block;
  margin-left: 2px;
  margin-right: 2px;
  padding: 0.35em 0.75em 0.625em;
  border: 1px groove rgba(52, 71, 103, 0.25);
  border-radius: 8px;
}
.form-wrapper .form-fieldset legend {
  width: auto;
  padding: 0.5em;
  font-size: 0.75rem;
}


.review-box .carousel-control {
  opacity: 1 !important;
  background-color: #51BCDAFF !important;
}

.review-box .carousel-control.left {
  left: -15px
}
.review-box .carousel-control.right {
  right: -15px
}
.review-comment-box {
  padding-right: 4px;
}
.review-comment-box::-webkit-scrollbar {
  width: 8px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.review-comment-box::-webkit-scrollbar-track {
  background-color: rgba(81, 188, 218, 0.35);
  border-radius: 8px;
}

.review-comment-box::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: solid 1px rgba(81, 188, 218, 1);
  background: repeating-linear-gradient(45deg, rgba(81, 188, 218, 1), rgba(81, 188, 218, 1) 10px, rgba(81, 188, 218, 0.5) 10px, rgba(81, 188, 218, 0.5) 20px);
}

#dictionaries-card {
  position: relative;
}

.loader-card {

}
#dictionaries-card:has(.loader-card):before {
  content: ' ';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 37, 55, 0.11);
  border-radius: 12px;
  z-index: 1000000;
}

.text-black2 {
  color: black !important;
}


.btn-group-tabs {
  width: 100% !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap;
  display: block !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.descr-tab-text {
  font-size: 18px;
  white-space: pre-wrap;
}
.pac-container {
  z-index: 9999999991100 !important;
}

.autocomplete-control {
  z-index: 1100 !important;
  width: 480px !important;
}
.autocomplete-container {
  z-index: 1100 !important;
  width: 100% !important;
}
@media (max-width: 768px) {
  .descr-tab-text {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .descr-tab-text {
    font-size: 13px;
  }
}

@media (max-width: 380px) {
  .descr-tab-text {
    font-size: 11px;
  }
}

@media (max-width: 1200px) {
  .footer-nav li a {
    font-size: 0.55em !important;
  }
  .footer .copyright {
    font-size: 0.75em !important;
  }
}
@media (max-width: 1000px) {
  .footer-nav li {
    padding: 8px !important;
    margin: 1px !important;
  }
  .footer-nav li a {
    font-size: 0.5em !important;
  }
  .footer .copyright {
    font-size: 0.65em !important;
  }
}

@media (max-width: 786px) {
  .footer-nav li {
    padding: 4px !important;
    margin: 0 !important;
  }
  .footer-nav li a {
    font-size: 0.45em !important;
  }
  .footer .copyright {
    font-size: 0.5em !important;
  }
}

@media (max-width: 640px) {
  .footer-nav li {
    display: block !important;
    padding: 0 !important;
    padding-top: 12px !important;
  }
  .footer .credits {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media (max-width: 420px) {
  .footer-nav {
    width: 100% !important;
  }
  .footer .credits {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.d-grid {
  display: grid!important;
}



.nav-open .navbar-icon-btn {
  background-color: #B6ABF9;
  border-radius: 0 !important;
  margin-left: -10px;
  margin-top: -18px;

}
.nav-open .navbar-icon-btn .nav-link span {
  font-weight: 600 !important;
}

.nav-open .navbar-icon-btn svg {
  display: none;
}
@media (max-width: 767px) {
  .navbar-nav {
    margin: 1px !important;
  }
  .nav-open .navbar-icon-btn .MuiTypography-root {
    display: block !important;
  }
}

@media (max-width: 420px) {
  .noUi-handle-upper .noUi-tooltip {
    margin-left: -12px;
  }
}

.full-booking-hidden {
  background-color: #FFFFFF !important;
  border-radius: 0 !important;
  border-top: solid 1px #bfc1c4 !important;
  border-left: solid 1px #f0f2f5 !important;
}


.full-booking-hidden::after {
  content: '';
  background-color: rgba(40, 167, 69, 0.5);
  border-color: rgba(40, 167, 69, 0.75);

  position: absolute;
  width: calc(100% + 1px);
  height: 2px;
  margin-left: -1px;
  border-radius: 3px;
}

.hide-full-switch .MuiBox-root {
  border-radius: 8px;
  border: solid 1px #bfc1c4;
  width: 100%;
  max-width: 320px;
  right: 46px;
  position: absolute;
  text-align: center;
  padding: 4px;
}

.hide-full-switch {
  height: 54px;
}

.about-us-page {
  min-height: calc(100vh - 85px);
}

.about-us-page .about-col-title {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.017em;
  color: #000000;
  text-align: left;
}

.about-us-page .about-col-body {
  font-family: Inter, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  padding: 30px 0;
}
.about-us-page .col-md-6:first-child .about-col-title {
  margin-top: 80px !important;
}

.navbar-icon-badge {
  cursor: pointer;
  margin: 0px;
  display: flex;
  flex-flow: wrap;
  -webkit-box-pack: center;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  min-width: 20px;
  z-index: 1;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 10px;
  margin-left: -2px;
  transform: scale(1) translate(50%, -50%);
  transform-origin: 100% 0%;
  height: auto;
  padding: 0.45em;
  font-size: 0.65rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: none;
  border-radius: 10rem;
  background: linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));
  color: rgb(255, 255, 255);
}

.fc-event-past {
  #width: 102%;
}

.review-comment-box {
  height: 190px !important;
}


.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 4px solid;
  border-color: inherit;

  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg);
  -ms-transform:rotate(-45deg);
  -o-transform:rotate(-45deg);
  transform:rotate(-30deg) translateY(-5px);
}

.price-tag {
  background-color: #4285F4;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285F4;
}

#cropperjs {
  display: block;
  max-width: 100%;
}
div:has(>#cropperjs) {
  overflow: hidden;
  width: 100%;
}

#preview {

}

.nav-link {
  /* menu */

  box-sizing: border-box !important;

  /* Auto layout */
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 12px 24px !important;
  gap: 4px !important;


  height: 48px !important;

  border: 1px solid #EDEDED !important;
  border-radius: 40px !important;

  /* Body Text/18r */
  font-family: 'Karla', serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  /* identical to box height, or 133% */

  /* dark */
  color: #0D0314 !important;


}

.nav-link:not(&.active):hover {

  border: 1px solid #c8c8c8 !important;

}
.nav-link.active {
  /* dark */
  background: #0D0314!important;
  /* dark */
  border: 1px solid #0D0314!important;
  /* white */
  color: #FFFFFF!important;
  opacity: 1!important;
}

.nav-link.active:hover {
  /* dark */
  background: #0D0314!important;
  /* dark */
  border: 1px solid #0D0314!important;
  /* white */
  color: #FFFFFF!important;
}

.login-nav {
  /* Button */


  height: 48px !important;

  /* purple */
  background: #7416B4 !important;
  border-radius: 40px !important;


  font-family: 'Karla', serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 26px !important;

  color: #FFFFFF !important;


}

.nav-item:has(.login-nav) {
  justify-self: end;
}
.main-navbar .container {

}

.nav-lang {

  background: #ECEAF0 !important;
  border-radius: 24px !important;


  font-family: 'Inter', serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  margin: 15px 3px;

  color: #0D0314 !important;
  height: 48px !important;

  border: none !important;
  width: 103px !important;
  padding-left: 20px;
  padding-right: 24px;
}
.nav-lang-value {
  /* EN */
  font-weight: 400 !important;
  color: #0D0314!important;


}
.nav-lang-indicator {
  width: 25% !important;
  margin-right: 4px;
}
.nav-lang-indicator .text-dark {
  padding: 0!important;

}





.footer-new {
  /* Footer  */


  padding: 80px 84px !important;

  width: 100% !important;
  height: 479px !important;

  /* dark */
  background: #0D0314 !important;

  /* Inside auto layout */
  flex: none !important;
  order: 3 !important;
  align-self: stretch !important;
  flex-grow: 0 !important;

}

.footer-new .top-row {
  height: 200px;
  margin-bottom: 64px;
}

.footer-new .top-row .footer-logo {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;

  /* white */
  color: #FFFFFF;
}

.footer-new .top-row .footer-title {
  /* Body Text/24 r */
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 24px;
}

.footer-new .top-row .footer-nav-link {

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0px;

  height: 38px;


  /* 14r */
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  /* white */
  color: #FFFFFF;

}
.footer-new .top-row .footer-nav-link svg {
  margin-left: -2px;
  margin-right: 8px;
}
.footer-new .top-row .footer-form {
  /* Frame 96 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 12px;

  height: 88px;
  margin-top: 24px;

}

.footer-new .top-row .footer-sublogo {

  /* Body Text/14 r */
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  letter-spacing: 0.02em;

  /* white */
  color: #FFFFFF;
  max-width: 360px;
  margin-top: 24px;
}
.footer-new .top-row .footer-form input {

  margin-left: -12px;
  padding: 16px 24px;
  height: 56px;
  min-width: 250px;

  /* white */
  background: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 40px;

  /* Body Text/16 r */
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.02em;

  color: #969696;


}

.footer-new .top-row .footer-form label {
  /* Email Address */

  width: 0px;
  white-space: nowrap;
  height: 24px;

  /* Body Text/16 r */
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.02em;

  /* white */
  color: #FFFFFF;
}








.footer-new .top-row .footer-form button {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;

  width: 56px;
  height: 56px;

  /* white */
  background: #FFFFFF;
  border-radius: 40px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

  /* purple */
  border: 1.5px solid #7416B4;

}

.footer-new .bottom-row {
  border-top: solid 1px rgba(68, 68, 68, 1);
  height: 55px;

  display: flex;
  align-content: flex-end;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
}
.footer-new .bottom-row .col {
  padding: 0;
}

.footer-new .bottom-row .bottom-links a {

  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-decoration: underline 0.5px !important;

  /* white */
  color: #FFFFFF !important;
  margin-left: 32px;

}

.footer-new .bottom-row .bottom-copy {

  /* Body Text/14 r */
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: 0.02em;

  /* white */
  color: #FFFFFF;

}

@media (max-width: 991px) {


    .nav-open .navbar-collapse {
      left: 16px !important;
    }
  .navbar-collapse.navbar-translate-collapse:has(.login-menu-wrapper) {
    height: 482px !important
  }
  .navbar-collapse.navbar-translate-collapse:has(.login-menu-wrapper) li {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .footer-new {
    height: 893px !important;
    padding: 40px 16px 40px 16px !important;
    position: relative;
  }
  .footer-new .top-row .footer-form input {
    width: 80%;
  }
  .footer-new .bottom-row {
    height: 40px;
    position: absolute;
    bottom: 8px;
    display: flex;
    align-items: baseline;
    padding-right: 42px;
    width: 100%;
  }
  .footer-new .bottom-row .bottom-links a {
    margin-left: 16px;
    white-space: nowrap;
  }
  .footer-new .footer-title{
    margin-top: 30px;
  }
  .footer-new .footer-newsletter {
    margin-top: -40px;
  }

}


.main-navbar.navbar.bg-white {
  box-shadow: none !important;
}




.day-row {
  margin-bottom: 1rem;
}

.time-slot .form-control {
  max-width: 100px;
  text-align: center;
}

.time-slot .input-group-text {
  cursor: pointer;
  border-right: solid !important;
}


.swal2-container {
  z-index: 999999999999990000 !important;
}

ul:has(.tp1-sectionItem) {
  width: 100px !important
}
.tp1-sectionItem {
  width: 100px !important
}

.tp1-toolbar {
  width: 200px !important;
  align-items: center !important;
}
.tp1-toolbar .MuiPickersToolbar-content {
  width: 200px !important;
  justify-content: center !important;
}
.weekly-editor-row .MuiTextField-root.tp1 {
  width: 130px !important;
  min-width: 96px !important;
}

.weekly-editor-row .MuiTextField-root.tp1 .MuiInputAdornment-root,
.weekly-editor-row .MuiTextField-root.tp1 .MuiButtonBase-root{
  max-width: 30px !important;
}

.weekly-editor-row .MuiTextField-root.tp1 input {
  min-width: 35px !important;
}



.weekly-editor .col, .weekly-editor .col-4 {
  #border: solid 1px green !important;

}
.weekly-editor {
  border-radius: 12px;
  padding: 0 25px;
  width: 100%;
  border: solid 1px #7416B4;
  margin-top: 32px;
  margin-bottom: 48px;
}
.weekly-editor.all-closed {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}


.weekly-editor-actions {
  width: 130px !important;
  max-width: 130px !important;
  position: relative;
}


.weekly-editor-actions>div {
  width: 100% !important;
  display: flex;
}


.weekly-editor-legend {
  width: auto;
  padding: 0.5em;
  font-size: 1.25rem;
}

.weekly-editor-row {
  font-size: 14px;
  padding-bottom: 2px;
  margin-bottom: 2px;
  border-bottom: solid 1px #7416B4
}

.weekly-editor-row:last-child {
  border-bottom: none;
}

.weekly-editor-row:not(:has(.keep-width)) .MuiTextField-root {
 width: 100% !important;
}
td:has(.keep-width) {
  min-width: 64px !important;
  max-width: 140px !important;
}


.weekly-editor .top-row>div {
  font-weight: 600;
  text-align: center;
  padding: 0 !important;

  font-size: 1rem;
}

.weekly-editor .top-row {
  padding: 0;
}

.weekly-editor-row>div {
  padding: 0 !important;

}

.weekly-editor-stack {
  border: solid 1px #51bcda;
  border-radius: 6px;
  padding: 16px 8px;
}

.table-weekly {
  width: 100%;
  table-layout: fixed
}


.table-weekly th {
  white-space: nowrap;
}
.weekly-editor-row.top-row .col:first-child{
  margin-bottom: 8px;
}

.table-weekly tbody {
  width: 100%;
}
.table-weekly tbody tr {
  width: 100%;
}

.table-weekly .table-weekly-td {
  padding: 8px 12px;
  max-width: calc((100% - 368px) / 4);
  min-width: calc((100% - 368px) / 4);
}

.calendar-children-box:has(.table-weekly) {
  padding: 12px;
}

.table-weekly-td-responsive {
  display: none;
}

.table-weekly {
  border-collapse: separate;
  border-spacing: 0;
}
.stack-td {
  width: 240px;
  max-width: 240px;
  min-width: 240px;
}

@media (max-width: 1200px) {
  .table-weekly:has(.table-weekly-td-responsive) {
    border-collapse: separate;
    border-spacing: 8px;
  }
  .table-weekly tr:has(.table-weekly-td-responsive) {
    border-radius: 10px;
    outline: 1px solid rgba(116, 22, 180, 0.25);
  }
  .table-weekly tr:has(.table-weekly-td-responsive) td:first-child {
    padding-left: 12px;
  }
  .table-weekly tr:has(.table-weekly-td-responsive) td:last-child>div {
   margin-left: -10px;
  }
  .table-weekly td.table-weekly-td {
    display: none;
  }
  .table-weekly th.table-weekly-td {
    opacity: 0;
  }
  .table-weekly-td-responsive {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 12px;
    padding: 12px;
  }
  .table-weekly-td-responsive>div {
    width: 40%;
  }
}
@media (max-width: 1100px) {
  .table-weekly-td-responsive>div {
    width: 100%;
  }

}
@media (max-width: 1024px) {
  .table-weekly-td-responsive>div {
    width: 100%;
  }
  .weekly-editor-row.top-row {
    display: none;
  }
  .table-weekly tr:has(.table-weekly-td-responsive) {
    display: block;
    margin-bottom: 20px;
  }
  .table-weekly tr:has(.table-weekly-td-responsive) td:last-child .MuiButtonBase-root {
    display: none;
  }
  .weekly-delete-button.d-none {
    display: block !important;
    position: absolute;
    right: 18px;
  }
  .stack-td {
    display: block;
    padding-top: 16px;
  }
}
@media (max-width: 520px) {
  .collapsed-weekly-row button {width: 100% !important;}
  .stack-td {
    width: 180px;
    max-width: 180px;
    min-width: 180px;
  }
  .weekly-editor-actions {
    width: 130px !important;
    max-width: 130px !important;
    position: relative;
  }
  .table-weekly tr.slot-selected {
    border: solid 2px rgba(40, 167, 69, 0.75) !important;
  }
  .table-weekly tr.slot-selected .table-weekly-td-responsive:has(input)>div div[class$="-control"],
  .table-weekly tr.slot-selected .weekly-responsive-comment-input
  {
    border: solid 2px rgba(40, 167, 69, 0.75) !important;
  }


  .weekly-editor-row .MuiTextField-root.tp1 {
    width: 100px !important;
    min-width: 76px !important;
  }

  .weekly-editor-row {
    flex-direction: column;
  }
  .weekly-editor-row.top-row {
    display: none;
  }
  .table-weekly tr:has(.table-weekly-td-responsive) {
    margin-bottom: 20px;
  }
  .weekly-save {
    top: -110px !important;
  }
  .table-weekly-td-responsive>div {
    margin-bottom: 10px;
  }

  .swal2-draft .rdw-editor-main {
    zoom: 0.75;
  }
}

.weekly-responsive-comment-input {
  padding: 4px !important;
  text-align: left;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0;
  border-color: rgba(0, 0, 0, 0.23);
  border-color: #d2d6da;
}
.weekly-responsive-comment-input>div {
  zoom: 0.75;
}

.weekly-editor-actions button:hover {
  color: red;
}

.menu-avatar-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  min-height: 72px;
  padding-bottom: 12px;
}

.menu-paper {
  background-color: white !important;
}

.top-menu-link .MuiMenuItem-root:hover {
  background-color: transparent !important;
}

.profile-header-container {
  margin-right: 12px;
}


.outlined-label {
  color: rgb(123, 128, 154);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0 6px !important;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(10px, -9px) scale(0.75);
  -moz-transform: translate(10px, -9px) scale(0.75);
  -ms-transform: translate(10px, -9px) scale(0.75);
  transform: translate(10px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white !important;
}
.table-weekly-td:has(div:hover) .outlined-label {
  color: #1A73E8 !important;
}
.table-weekly-td:has(div:active) .outlined-label {
  color: #1A73E8 !important;
}

.fc-v-event.text-black .fc-event-main {
  color: rgba(0, 0, 0, 0.87) !important;
}
.swal2-textarea {
  white-space: pre
}
.swal2-modal:has(.swal2-draft) {
  min-width: 50%;
}
.swal2-draft .rdw-editor-toolbar  {
  width: 100%;
}
.swal2-html-container:has(.swal2-draft)  {
  border-radius: 10px;
  border: 1px solid rgba(116, 22, 180, 0.5);
  margin-left: 6px;
  margin-right: 6px;
  padding: .75em;
  min-height: 100%;
}
.swal2-title-weekly {
  max-height: 80px;
}
.swal2-draft .rdw-editor-main {

}
.swal2-draft {
  height: 100%;
  width: 100%;
  margin-left: -6px;
  margin-top: -6px;
}

@media (max-height: 750px) {
  .no-wrapper-dialog .p-4 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    height: 100% !important;
    position: relative;
  }
  .no-wrapper-dialog:not(:has(.pre-booking-card)) {
    height: 100% !important;
    margin-bottom: 0 !important;
    margin-top: 0!important;
  }
  .no-wrapper-dialog:has(.pre-booking-card) {
    height: calc(100% - 90px) !important;
    margin-top: 90px !important;
  }
  .attendee-list-wrapper {
    max-height: calc(100% - 130px) !important;
    min-height: calc(100% - 130px) !important;
    height: calc(100% - 130px) !important;
    overflow-y: auto;
  }
}
.pre-booking-card-footer {
  text-align: right;
  margin-right: 24px;
}

@media (max-width: 680px) {
  .pre-booking-card-footer {
   position: absolute;
    bottom: 24px;
    right:0;
  }
  .pre-booking-card .MuiGrid-spacing-xs-3 {
    zoom: 0.9;
  }
  .pre-booking-card .MuiGrid-item {
    padding: 12px 4px !important;
  }
  .pre-booking-card:has(.attendees-avatar-list)  .pre-booking-card-body{
    margin-top: 48px;
  }
  .pre-booking-card .MuiGrid-container {
    margin: 0 !important;
  }
  .MuiBox-root:has(>.attendees-avatar-list) {
    padding-bottom: 16px;
  }
  .attendees-avatar-list {
    position: absolute;
    left: 24px;
    top: 64px;
    flex-direction: column;
  }
  .attendees-avatar-list .small {
   height: 12px;
  }
  .attendees-avatar-list .MuiBox-root {
   display: block;
  }
  .no-wrapper-dialog:not(:has(.pre-booking-card)) {
    height: 100% !important;
    margin-bottom: 0 !important;
    margin-top: 0!important;
  }
  .no-wrapper-dialog:has(.pre-booking-card) {
    height: calc(100% - 90px) !important;
    margin-top: 45px !important;
    max-height: calc(100% - 90px);
  }
  .no-wrapper-dialog .pre-booking-card{
    height: 100% !important;
    max-height: 100%;
  }
  .MuiListItem-root:has(.popup-list-item) {
    flex-direction: column;
    border-bottom: solid 2px #1A73E8;
  }
  .popup-list-item {
    margin-top: -95px;
  }
  .attendee-list-wrapper {
    max-height: calc(100vh - 130px) !important;
    overflow-y: auto;
  }
  .MuiPaper-elevation3 {
    padding-bottom: 120px !important;
  }
}

@media (max-height: 680px) {
  .pre-booking-card .MuiGrid-item {
    padding: 6px !important;
    margin: 0 !important;
  }
}

@media (max-width: 1200px) {
.fc-scroller-harness:has(.fc-scroller>.fc-timegrid-axis-chunk) {
#display: none;
}
  td:has(.fc-scroller-harness>.fc-scroller>.fc-timegrid-axis-chunk) {
    #width: 5px !important;
  }
}
@media (max-width: 810px) {
  .swal2-modal:has(.swal2-draft) {
    min-height: 100%;
    min-width: 100%;
  }
}
.attendee-list-wrapper {
  max-height: 75vh;
  overflow-y: auto;
}
.label-box-new .event-card-comment {
  margin-top: 2px;
  max-height: 50%;
  overflow-y: auto;
  zoom: 0.75;
  opacity: 1;
  color: #0b1011;
  padding: 2px 5px;
  background-color: rgba(255, 255, 255, 0.4);
}

.label-box-new {
  overflow-y: hidden;
}
.label-box-new .event-card-comment::before {
  content: '';
  position: absolute;
  width: 100%;
  height: fit-content;
  z-index: -1;
  background-color: white;
}

.label-box-new:has(.event-card-location) .event-card-comment {
  margin-top: 16px;
  max-height: 37%;
  overflow-y: auto;
  zoom: 0.75;
}

.weekly-editor-actions>div {
  margin-left: 8px;
}
.weekly-editor-actions button {
  width: 40px !important;
  min-width: 40px !important;
  padding: 1px !important;
}
.weekly-editor-actions .weekly-action {
  font-size: 20px !important;
}
.weekly-editor-actions #delete {
  background-color: #f5593d;
  margin-left: 4px;
}
.weekly-editor-actions #clone {
  margin-right: 4px;
}

.table-weekly-td-content {
  display: flex;
}

.table-weekly-td-content:has(.weekly-action-inline)>div {
  width: 100%;
}
.weekly-action-inline {
  width: 20px !important;
  min-width: 20px !important;
  padding: 0 !important;
  padding-left: 8px !important;
  display: inline;
}

.event-schedule.fc-event-past {
  width: 100% !important;
  height: calc(100% + 1px) !important;
}
.event-schedule {
  cursor: pointer;
  background-color: rgba(40, 167, 69, 0.5);
  border-color: rgba(40, 167, 69, 0.75);
}
.event-schedule:hover {
  background-color: rgba(40, 167, 69, 0.75);
  border-color: rgba(40, 167, 69, 1);
}

.no-wrapper-dialog {
  overflow-y: visible !important;
}

.MuiButton-root .text-bigger  {
  font-size: 1.25rem !important;
  margin-top: -0.125rem;
}

.arrow-white {
  color: white !important;
}

.grid-tooltip {
  max-width: 300px;
  width: 300px;
  margin-left: 100px !important;
}

.grid-tooltip .MuiTooltip-tooltip {
  max-width: 300px;
  width: 300px;
  margin-left: -100px !important;
}

.comment-paper ul {
    margin-left: 12px;
    margin-bottom: 6px;
}
.comment-paper li {
    line-height: 1.3;
}

.weekly-confirm-radio-group {
  margin-top: -8px;
}

.has-bookings::before {
  content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-top: 20px solid #fb8c00;
}

.event-schedule:has(.event-full) {
  background-color: rgb(144, 144, 144, 0.25);
  border-color: rgb(144, 144, 144, 0.5);
}
.fc-v-event:has(.event-info.event-full) {
  border: none !important;
}
.event-info.event-full {
  border: solid 1px #1A73E8 !important;
  margin-left: -1px;
  margin-top: -1px;
  height: calc(100% + 1px) !important;
  width: calc(100% + 2px) !important;
}
.event-info.event-full::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid white;
}
.event-info.event-full::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-top: 20px solid rgba(40, 167, 69, 0.75);
}


.coach-calendar .event-schedule {
  overflow-y: hidden;
}
.coach-calendar .fc-timegrid-col-events {
  margin-right: 0 !important;
}

.session-members-list {
  max-width: 200px !important;
  margin-top: 0;
  margin-right: 1px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fc-event-main >div:has(.session-members-list) {

  max-width: 230px !important;
  width: 230px !important;
}

.event-error.fc-list-event {
  background-image: linear-gradient(195deg, rgba(239, 83, 80, 0.75), rgba(229, 57, 53, 0.75)) !important;
}

.weekly-row-buttons .MuiButton-root svg.MuiSvgIcon-root {
  font-size: 1.25rem !important;
}

.table-weekly .table-weekly-td {
  padding-top: 6px !important;
}
.table-weekly tr {
  border-bottom: solid 1px rgba(182, 171, 249, 0.25);
}

.table-weekly tr.slot-selected {
  border-bottom: solid 2px rgba(40, 167, 69, 0.75) !important;
}
.table-weekly tr.slot-selected fieldset,
.table-weekly tr.slot-selected td:not(:has(fieldset)) .table-weekly-td-content>div>div>div,
.table-weekly tr.slot-selected td:not(:has(fieldset)):not(.table-weekly-td)>div>div>div
{
  border: solid 2px rgba(40, 167, 69, 0.75) !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #f5593d !important;
  border-color: #f5593d !important;
}

.event-info:has(.timeGridWeek-item):hover {
  background-image: linear-gradient(195deg, rgba(73, 163, 241, 0.75), #1A73E8BF) !important;
}

.event-success:has(.timeGridWeek-item):hover {
  background-image: linear-gradient(195deg, #66BB6ABF, #43A047BF) !important;
}

.event-error:has(.timeGridWeek-item):hover {
  background-image: linear-gradient(195deg, rgba(239, 83, 80, 0.85), rgba(229, 57, 53, 0.85)) !important;
}

.bg-transparent {
  background: transparent !important;
}

.comments-box {
  border-radius: 16px;
  padding: 8px;
  background: rgba(116, 22, 180, 0.05);
  zoom: 0.75;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.event-common-past {
  opacity: 0.5 !important;
}



.fc-timegrid th .fc-scroller {
  margin-bottom: 5px !important;
  overflow-y: hidden !important;
}



.fc-timegrid th .fc-scroller::-webkit-scrollbar {
  width: 12px;
}

.fc-timegrid th .fc-scroller::-webkit-scrollbar-track {
  background: #e0e0e0;
  border: 1px solid #bbb;
  border-radius: 6px;
}

.fc-timegrid th .fc-scroller::-webkit-scrollbar-thumb {
  /* Fallback background if SVG fails */
  border-radius: 4px;
  border: 1px solid #999;
  background: #ccc url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="100"><rect width="100%" height="100%" fill="lightgray"/><line x1="0" y1="5" x2="20" y2="5" stroke="gray" stroke-width="2"/><line x1="0" y1="20" x2="20" y2="20" stroke="gray" stroke-width="2"/><line x1="0" y1="35" x2="20" y2="35" stroke="gray" stroke-width="2"/><line x1="0" y1="50" x2="20" y2="50" stroke="gray" stroke-width="2"/><line x1="0" y1="65" x2="20" y2="65" stroke="gray" stroke-width="2"/><line x1="0" y1="80" x2="20" y2="80" stroke="gray" stroke-width="2"/><line x1="0" y1="95" x2="20" y2="95" stroke="gray" stroke-width="2"/></svg>');
  background-size: 100% 100%;
  animation: fancyPulse 2s ease-in-out;
}

.fc-timegrid th .fc-scroller::-webkit-scrollbar-thumb:hover {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="100"><rect width="100%" height="100%" fill="white"/><line x1="0" y1="5" x2="20" y2="5" stroke="darkgray" stroke-width="2"/><line x1="0" y1="20" x2="20" y2="20" stroke="darkgray" stroke-width="2"/><line x1="0" y1="35" x2="20" y2="35" stroke="darkgray" stroke-width="2"/><line x1="0" y1="50" x2="20" y2="50" stroke="darkgray" stroke-width="2"/><line x1="0" y1="65" x2="20" y2="65" stroke="darkgray" stroke-width="2"/><line x1="0" y1="80" x2="20" y2="80" stroke="darkgray" stroke-width="2"/><line x1="0" y1="95" x2="20" y2="95" stroke="darkgray" stroke-width="2"/></svg>');
}

.fc-timegrid th .fc-scroller::-webkit-scrollbar-thumb:active {
  background: #bbb;
}
.profile-calendar .fc-timegrid {
  --rightoffset: 0;
}
.profile-calendar .fc-timegrid.with-scroll::after {
  content: '';
  position: absolute;
  top: 0;
  opacity: 0;
  right: var(--rightoffset);
  width: 250px; /* Width of the indicator */
  height: 100%;
  z-index: 99999999999;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  animation: flash 1.5s 3; /* Flashing animation */
  pointer-events: none; /* Prevent interaction with the indicator */
}
.scroll-indicator  {
  opacity: 0;
}

.scroll-indicator.with-scroll {
  position: absolute;
  top: 50vh;
  right: -20px; /* Adjust positioning from the right edge */
  transform: translateY(-50%);
  font-size: 124px; /* Size of the indicator */
  opacity: 0;
  animation: flash 1.5s 3; /* Flashing animation */
  pointer-events: none; /* Prevent interaction with the indicator */
  z-index: 9999999999999;
}
.scroll-indicator svg {
  transform: scale(1, 4);
  fill: rgb(206, 199, 249);
}

@keyframes flash {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.coach-calendar .fc-toolbar-chunk:last-child {
  white-space: nowrap;
}

.profile-calendar .fc-toolbar-chunk:not(:has(.toolbar-alert-icon)):not(:has(.fc-toolbar-title)) {
  max-width: fit-content;
  min-width: fit-content;

}
.profile-calendar .fc-toolbar-chunk:has(.toolbar-alert-icon) {
  min-width: calc(100% - 520px);
  max-width: calc(100% - 520px);
}
.profile-calendar .fc-toolbar-chunk:has(.toolbar-alert-icon) .fc-button-primary {
  display: none !important;
}
.profile-calendar .fc-toolbar-chunk:has(.fc-toolbar-title) {
  max-width: 220px;
  min-width: 160px;

}
.profile-calendar .fc-toolbar {
  max-width: 100%;
  flex-wrap: nowrap
}

.fc-toolbar-chunk>.fc-alert1-button {
  opacity: 0 !important;
  border: solid 1px red;
}

.fc-toolbar-chunk:has(>.fc-alert1-button),
.fc-toolbar-chunk:has(>#alert1) {
  padding: 0 30px !important;
  max-width: calc(100% - 400px);
}

@media (max-width: 1200px) {
  .fc-toolbar-chunk:has(>.fc-alert1-button),
  .fc-toolbar-chunk:has(>#alert1) {
    padding: 0 !important;
    max-width: calc(100% - 435px);
  }

}
@media (max-width: 500px) {
  .fc-toolbar-chunk:has(>.fc-alert1-button),
  .fc-toolbar-chunk:has(>#alert1) {
    padding: 0 !important;
    max-width: calc(100% - 400px);
  }

}



.fc .fc-button.fc-button-primary,
.fc .fc-button.fc-button-primary.fc-today-button
{
  background-color: #8330BC !important;
  border-color: #8330BC !important;
}

.fc .fc-button.fc-button-primary:hover,
.fc .fc-button.fc-button-primary.fc-today-button:hover
{
  background-color: #8330BC !important;
  border-color: #8330BC !important;
}

.fc .fc-button.fc-modal1-button {
  padding: 12px 48px;
  font-size: 17px !important;
  font-weight: 600!important;
  background-color: rgb(236, 64, 122) !important;
  background-image: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96)) !important;
  border-color: rgb(216, 27, 96) !important;
  white-space: nowrap;
}


.fc .fc-button.fc-modal1-button:hover {
  padding: 12px 48px;
  font-size: 17px !important;
  font-weight: 600!important;
  background-color: rgb(236, 64, 122) !important;
  background-image: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96)) !important;
  border-color: rgb(216, 27, 96) !important;
}


body {
  overflow-y: auto !important;
}


.popup-list-item .MuiButton-contained {
  color: white !important;
}

.popup-list-item .fc-event.event-error  {
  color: white !important;
}

body .fc-event.event-error *:not(.MuiButton-contained) {color: black !important;}

.font-bigger p {
  font-size: 110% !important;
}

.fc-toolbar-chunk:has(.fc-modal1-button) {
  display: flex;
  align-items: flex-start
}


.toolbar-alert-icon {
  min-width: 60px;
  text-align: center;
}
.toolbar-alert-icon::before {
  content: ' ';
  position: absolute;
  width: 60px;
  height: 100%;
  left:0;
  top:0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #1A73E8;
  z-index: -1;
}

.fc-toolbar-chunk .fc-toolbar-title {

  white-space: nowrap;
}
.fc-toolbar-chunk .fc-alert1-button.fc-button.fc-button-primary {
  display: none;
}
.fc-toolbar-chunk .fc-modal1-button {
  height: 34px !important;
  padding: 0 0.6em !important;
  margin: auto;
}


@media (max-width: 1200px) {
  .fc-toolbar-chunk:has(>.fc-alert1-button), .fc-toolbar-chunk:has(>#alert1) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px !important;
  }
  .fc-toolbar-chunk .fc-modal1-button {
    margin-bottom: 4px;
  }

  .fc-toolbar-chunk .fc-modal1-button {
    height: 34px !important;
    padding: 0 0.6em !important;
  }
}
@media (max-width: 992px) {
  .fc-toolbar-chunk:has(>.fc-alert1-button), .fc-toolbar-chunk:has(>#alert1) {

    padding-bottom: 0 !important;
  }
}
.profile-container-new {
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1400px) {
  .profile-avatar {
    margin-left: 2%;
  }
  .profile-container-new {
    width: 80%;
  }
}
@media (max-width: 1300px) {
  .profile-container-new {
    width: 86%;
  }
}
.profile-tabs-1 {
  min-width: 800px !important;
}
@media (max-width: 1200px) {
  .profile-container-new {
    width: 92%;
  }
  .MuiGrid-item:has(>.profile-avatar) {
    max-width: 230px !important;
  }
  .profile-avatar {
    width: 220px !important;
    height: 220px !important;
  }

}
@media (max-width: 1140px) {
  .profile-container-new {
    width: 96%;
  }
}
@media (max-width: 1080px) {
  .profile-container-new {
    width: 98%;
  }
}
@media (max-width: 940px) {
  .MuiGrid-item:has(>.profile-avatar) {
    max-width: 170px !important;
  }
  .profile-avatar {
    width: 150px !important;
    height: 150px !important;
    margin-left: 5%;
  }
}

.profile-preview-wrapper .justify-content-between>button{
  pointer-events: none;
}
.profile-preview-wrapper1:after {
  content: ' ';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  border: solid 1px red;
}

.fc.week-view .fc-prev-button {
  display: none;
}
.fc.week-view .fc-next-button {
  display: none;
}
.fc.week-view .fc-toolbar-title {
  display: none;
}

.client-bookings .fc-toolbar-chunk:nth-child(2) {
  margin-top: 15px;
}

.left-column {
  width: 195px;
  font-size: 16px;
  position: absolute;
  left: 0;
  text-align: center;
  padding-top: 140px
}
.left-column .text-dark {
  font-weight: 500;
  font-size: 24px;
}

@media (max-width: 520px) {
  .left-column {
    padding-top: 0;
    left: 50%;
    margin-top: -50px;
    font-size: 14px;
    line-height: 133%;
  }

  .left-column .text-dark {
    font-weight: 500;
    font-size: 18px;
  }
}
