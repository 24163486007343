@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,300,500,600");

body {
    overflow-x: hidden;
    font-family: 'Lato', sans-serif !important;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Thin-BETA.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Thin-BETA.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-ThinItalic-BETA.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-ThinItalic-BETA.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-ExtraLight-BETA.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-ExtraLight-BETA.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-ExtraLightItalic-BETA.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-ExtraLightItalic-BETA.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Light-BETA.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Light-BETA.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-LightItalic-BETA.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-LightItalic-BETA.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Regular.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Regular.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Italic.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Italic.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Medium.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Medium.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-MediumItalic.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-MediumItalic.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-SemiBold.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-SemiBold.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-SemiBoldItalic.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-SemiBoldItalic.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Bold.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Bold.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-BoldItalic.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-BoldItalic.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-ExtraBold.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-ExtraBold.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-ExtraBoldItalic.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-ExtraBoldItalic.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-Black.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-Black.woff?v=3.10a') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('https://static.figma.com/webfont/1/Inter-BlackItalic.woff2?v=3.10a') format('woff2'),
    url('https://static.figma.com/webfont/1/Inter-BlackItalic.woff?v=3.10a') format('woff');
}

.about-us-v9lB7P {
    overflow: hidden;
    opacity: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.header-hlgf4F {
    opacity: 1;
    position: relative;
    min-height: 90px;
    width: 100%;
    gap: 245px;
    margin: 0 auto;
    padding-left: 108px;
    padding-right: 108px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 1);
}

.spotya-U0KoTr {
    opacity: 1;
    position: relative;
    min-height: 47px;
    width: 131px;
    color: rgba(116.00000068545341, 22.000000588595867, 180.00000447034836, 1);
    white-space: pre;
    height: 47px;
    font-size: 40px;
    line-height: 46.8px;
    text-align: left;
    font-weight: 700;
}

.language-switherbtn-U0KoTr {
    opacity: 1;
    position: relative;
    min-height: 50px;
    width: 241px;
    gap: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ua-r5LQ50 {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 1;
    position: relative;
    min-height: 50px;
    width: 103px;
    gap: 12px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(236.4049530029297, 233.69346410036087, 239.92993265390396, 1);
}

.en-Me3Ctw {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 19px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    white-space: pre;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.frame-Me3Ctw {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 24px;
    overflow: hidden;
}

.button-r5LQ50 {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 114px;
    gap: 8px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 11px;
    padding-bottom: 11px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(116.00000068545341, 22.000000588595867, 180.00000447034836, 1);
}

.button-Xyy2qB {
    opacity: 1;
    position: relative;
    min-height: 26px;
    width: 50px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 26px;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    font-weight: 500;
}

.header-nenu-U0KoTr {
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 353px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu-MbCn19 {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 96px;
    gap: 4px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    border-width: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
}

.home-Qo9n6k {
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 48px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.menu-zqj3lK {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 123px;
    gap: 4px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(236.54321998357773, 236.54321998357773, 236.54321998357773, 1);
}

.about-us-vY7IOY {
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 75px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    white-space: pre;
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.menu-Pq1Z50 {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 118px;
    gap: 4px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(236.54321998357773, 236.54321998357773, 236.54321998357773, 1);
}

.coaches-jy4Ehm {
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 70px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    white-space: pre;
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.hero-hlgf4F {
    opacity: 1;
    position: relative;
    min-height: 808px;
    width: 100%;
    margin: 0 auto;
}

.hero-page-lbYjbP {
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    overflow: hidden;
    opacity: 1;
    z-index: 2;
    position: absolute;
    top: 8px;
    left: 24px;
    width: 1392px;
    height: 800px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.30000001192092896);
}

.titletext-ViSrhg {
    opacity: 1;
    z-index: 3;
    position: absolute;
    top: 628px;
    left: 84px;
    width: 453px;
    height: 132px;
    display: flex;
    flex-direction: column;
}
@media (max-width: 991px) {
    .about-us-v9lB7P {

        width: 100% !important;
    }
    .content-hlgf4F {
        gap: 24px !important;
    }
    .titletext-ViSrhg {
        left: 0;
        padding: 12px;
    }
    .hero-header {
        font-family: Karla, serif;
        font-size: 44px !important;

        margin-top: -36px;
    }
    .numbers-rC60Ku {
        max-width: 100%;

        display: flex!important;
        flex-direction: column!important;
        align-items: center !important;
    }
    .about-us-rC60Ku {
        max-width: 100%;
        padding: 16px !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .mission-rC60Ku {
        max-width: 100%;

        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .mission-rC60Ku .content-jFwm02 {
        padding: 16px;
        position: relative;
        min-height: 100%;
        width: fit-content;
        gap: 18px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgba(242.40771889686584, 242.40771889686584, 242.40771889686584, 1);
    }
    .to-make-finding-sports-coaches-easy-for-everyone-ZQMZg4,
    .we’e-noticed-that-many-people-have-a-hard-time-finding-the-right-coach-to-start-with-at-spotya-we-help-by-quickly-connecting-clients-with-trustworthy-coaches-discover-coach-profiles-reviews-schedules-and-book-appointments-smoothly-it-will-help-you-to-feel-confident-in-decision-making-and-save-coaches-time-on-proving-themselves-ZQMZg4,
    .spotya-aims-to-make-the-process-of-finding-and-booking-a-sports-coach-simple-and-efficient-for-everyone-we-believe-that-everyone-deserves-access-to-quality-coaching-and-we-provide-the-tools-to-make-this-happen-Mk6lqA,
    .titlesubtitle-Mk6lqA,
    .our-mission-tH9iVw,
    .simplify-your-sports-coaching-journey-with-spotya-tH9iVw {
        width: 100% !important;
        height: fit-content !important;
    }
    .mission-rC60Ku {
        padding: 16px !important;
        height: fit-content!important;
    }
    .about-us-rC60Ku p {
        padding: 16px !important;
        height: fit-content;
    }
    .numbers-rC60Ku div {

    }
    .to-make-finding-sports-coaches-easy-for-everyone-ZQMZg4,
    .simplify-your-sports-coaching-journey-with-spotya-tH9iVw {
        font-size: 32px !important;
        line-height: 40px !important;
    }
    .we’e-noticed-that-many-people-have-a-hard-time-finding-the-right-coach-to-start-with-at-spotya-we-help-by-quickly-connecting-clients-with-trustworthy-coaches-discover-coach-profiles-reviews-schedules-and-book-appointments-smoothly-it-will-help-you-to-feel-confident-in-decision-making-and-save-coaches-time-on-proving-themselves-ZQMZg4,
    .spotya-aims-to-make-the-process-of-finding-and-booking-a-sports-coach-simple-and-efficient-for-everyone-we-believe-that-everyone-deserves-access-to-quality-coaching-and-we-provide-the-tools-to-make-this-happen-Mk6lqA {
        font-size: 16px !important;
    }
    .photo-jFwm02 {
        padding: 0 !important;
        margin-left: 16px;
        margin-right: 16px;
        width: 100% !important;
        height: auto !important;
        min-height: auto !important;
    }

    .why-us-n32K3b {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 26px !important;
        text-align: center !important;
        width: 100% !important;
    }
    .our-commitment-to-you-n32K3b {
        font-size: 32px !important;
        font-weight: 500 !important;
        line-height: 40px !important;
        text-align: center !important;
        width: 100% !important;

    }
    .titlesubtitle-Y0rnOi {
        width: 100% !important;
    }
    .why-us-rC60Ku {
        min-height: fit-content !important;
        padding: 16px !important;
    }
    .content-Y0rnOi {
        width: 100% !important;
        min-height: fit-content !important;
        display: flex;
        flex-direction: column;
    }
    .content-Y0rnOi > div {
        gap: 0 !important;
        min-height: fit-content !important;
    }
    .content-Y0rnOi div {
        width: 100% !important;
        min-height: fit-content !important;
        display: flex!important;
        flex-direction: column !important;
    }
    .content-Y0rnOi div img {
        width: 100% !important;
        min-height: fit-content !important;
    }
    .content-Y0rnOi div p {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        min-height: 120px !important;
    }


    .block-XL33DW {
        display: flex!important;
        flex-direction: column !important;
    }

    .block-XL33DW p:first-child {
        margin-top: 16px !important;
      font-size: 24px;
      line-height: 32px;
        min-height: fit-content !important;
    }

    .content-Y0rnOi .block-9yBehs {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .content-Y0rnOi .block-9yBehs p:first-child {
        margin-top: 16px !important;
      font-size: 24px;
      line-height: 32px;
        min-height: fit-content !important;
    }

    .content-Y0rnOi .block-KGUG6Z {
        display: flex !important;
        flex-direction: column !important;
    }

    .content-Y0rnOi .block-KGUG6Z p:first-child {
        margin-top: 16px !important;
      font-size: 24px;
      line-height: 32px;
        min-height: fit-content !important;
    }
    .content-Y0rnOi .block-woNqdT {
        display: flex !important;
        flex-direction: column-reverse !important;
    }

    .content-Y0rnOi .block-woNqdT p:first-child {
        margin-top: 16px !important;
      font-size: 24px;
      line-height: 32px;
        min-height: fit-content !important;
    }
}

.about-spotya-HlSvDX {
    opacity: 1;
    position: relative;
    min-height: 84px;
    width: 453px;
    color: rgba(255, 255, 255, 1);
    height: 84px;
    font-size: 72px;
    line-height: 84.24px;
    text-align: left;
}

.lorem-ipsum
is-simply-dummy-text-of-the-printing-and-typesetting-industry-lorem-ipsum-has-been-HlSvDX {
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 453px;
    color: rgba(255, 255, 255, 1);
    height: 48px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.content-hlgf4F {
    opacity: 1;
    position: relative;
    min-height: 3572px;
    width: 100%;
    gap: 160px;
    margin: 0 auto;
    padding-top: 120px;
    padding-bottom: 160px;
    display: flex;
    flex-direction: column;
}

.numbers-rC60Ku {
    opacity: 1;
    position: relative;
    min-height: 108px;
    width: 100%;
    gap: 24px;
    padding-left: 108px;
    padding-right: 108px;
    display: flex;
    flex-direction: row;
}

.number-57eJYP {
    opacity: 1;
    position: relative;
    min-height: 108px;
    width: 288px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.x88-UA3lca {
    opacity: 1;
    position: relative;
    min-height: 64px;
    width: 288px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 64px;
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    font-weight: 500;
}

.type-of-sports-on-offer-UA3lca {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 288px;
    color: rgba(146.30126953125, 146.30126953125, 146.30126953125, 1);
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.number-elH6ha {
    opacity: 1;
    position: relative;
    min-height: 108px;
    width: 288px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.x165-OX69xr {
    opacity: 1;
    position: relative;
    min-height: 64px;
    width: 288px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 64px;
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    font-weight: 500;
}

.avaliable-coaches-OX69xr {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 288px;
    color: rgba(146.30126953125, 146.30126953125, 146.30126953125, 1);
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.number-eNvUM6 {
    opacity: 1;
    position: relative;
    min-height: 108px;
    width: 288px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.x4k-IC3bfC {
    opacity: 1;
    position: relative;
    min-height: 64px;
    width: 288px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 64px;
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    font-weight: 500;
}

.found-their-perfect-coach-IC3bfC {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 288px;
    color: rgba(146.30126953125, 146.30126953125, 146.30126953125, 1);
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.number-yB1k1W {
    opacity: 1;
    position: relative;
    min-height: 108px;
    width: 288px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.x97-nrum84 {
    opacity: 1;
    position: relative;
    min-height: 64px;
    width: 288px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 64px;
    font-size: 56px;
    line-height: 64px;
    text-align: center;
    font-weight: 500;
}

.positive-reviews-nrum84 {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 288px;
    color: rgba(146.30126953125, 146.30126953125, 146.30126953125, 1);
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
}

.about-us-rC60Ku {
    opacity: 1;
    position: relative;
    min-height: 120px;
    width: 100%;
    gap: 24px;
    padding-left: 108px;
    padding-right: 108px;
    display: flex;
    flex-direction: row;
}

.to-make-finding-sports-coaches-easy-for-everyone-ZQMZg4 {
    opacity: 1;
    position: relative;
    min-height: 120px;
    width: 600px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 120px;
    font-size: 48px;
    line-height: 56px;
    text-align: left;
    font-weight: 500;
}

.we’e-noticed-that-many-people-have-a-hard-time-finding-the-right-coach-to-start-with-at-spotya-we-help-by-quickly-connecting-clients-with-trustworthy-coaches-discover-coach-profiles-reviews-schedules-and-book-appointments-smoothly-it-will-help-you-to-feel-confident-in-decision-making-and-save-coaches-time-on-proving-themselves-ZQMZg4 {
    opacity: 1;
    position: relative;
    min-height: 120px;
    width: 600px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 120px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.mission-rC60Ku {
    opacity: 1;
    position: relative;
    min-height: 680px;
    width: 100%;
    gap: 24px;
    padding-left: 108px;
    padding-right: 108px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.content-jFwm02 {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 1;
    position: relative;
    min-height: 680px;
    width: 600px;
    gap: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(242.40771889686584, 242.40771889686584, 242.40771889686584, 1);
}

.titlesubtitle-Mk6lqA {
    opacity: 1;
    position: relative;
    min-height: 226px;
    width: 520px;
    gap: 18px;
    display: flex;
    flex-direction: column;
}

.our-mission-tH9iVw {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 520px;
    color: rgba(119.32787731289864, 119.32787731289864, 119.32787731289864, 1);
    height: 40px;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 40px;
    text-align: left;
}

.simplify-your-sports-coaching-journey-with-spotya-tH9iVw {
    opacity: 1;
    position: relative;
    min-height: 168px;
    width: 520px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 168px;
    font-size: 48px;
    line-height: 56px;
    text-align: left;
    font-weight: 500;
}

.spotya-aims-to-make-the-process-of-finding-and-booking-a-sports-coach-simple-and-efficient-for-everyone-we-believe-that-everyone-deserves-access-to-quality-coaching-and-we-provide-the-tools-to-make-this-happen-Mk6lqA {
    opacity: 1;
    position: relative;
    min-height: 96px;
    width: 520px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 96px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.photo-jFwm02 {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 680px;
    width: 600px;
    overflow: hidden;
}

.why-us-rC60Ku {
    opacity: 1;
    position: relative;
    min-height: 1904px;
    width: 100%;
    gap: 48px;
    padding-left: 108px;
    padding-right: 108px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titlesubtitle-Y0rnOi {
    opacity: 1;
    position: relative;
    min-height: 104px;
    width: 1224px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.why-us-n32K3b {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 1224px;
    color: rgba(119.32787731289864, 119.32787731289864, 119.32787731289864, 1);
    height: 40px;
    font-size: 24px;
    text-transform: uppercase;
    line-height: 40px;
    text-align: center;
}

.our-commitment-to-you-n32K3b {
    opacity: 1;
    position: relative;
    min-height: 56px;
    width: 1224px;
    color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
    height: 56px;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    font-weight: 500;
}

.content-Y0rnOi {
    opacity: 1;
    position: relative;
    min-height: 1752px;
    width: 1224px;
    gap: 40px;
    display: flex;
    flex-direction: column;
}

.block-XL33DW {
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 1224px;
    gap: 104px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-p7dkh8 {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 600px;
}

.frame-4-p7dkh8 {
    opacity: 1;
    position: relative;
    min-height: 176px;
    width: 520px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.discover-top-sports-instructors-near-you-PRYdRi {
    opacity: 1;
    position: relative;
    min-height: 80px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 80px;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    font-weight: 500;
}

.to-help-explore-the-closest-and-finest-options-near-you-by-starting-to-train-today-by-using-our-filtering-page-additional-features-will-allow-you-to-find-exactly-what-you’re-looking-for-PRYdRi {
    opacity: 1;
    position: relative;
    min-height: 72px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 72px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.block-9yBehs {
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 1224px;
    gap: 104px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frame-4-vhpn4e {
    opacity: 1;
    position: relative;
    min-height: 136px;
    width: 520px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.comprehensive-instructor-profiles-4FPXuH {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 40px;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    font-weight: 500;
}

.allow-to-check-out-all-the-information-social-media-prices-locations-certificates-reviews-and-more-to-pick-the-best-sports-instructor-for-yourself-4FPXuH {
    opacity: 1;
    position: relative;
    min-height: 72px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 72px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-vhpn4e {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 600px;
}

.block-KGUG6Z {
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 1224px;
    gap: 104px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-86fW4y {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 600px;
}

.frame-4-86fW4y {
    opacity: 1;
    position: relative;
    min-height: 136px;
    width: 520px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.convenient-booking-process-7QEvON {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 40px;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    font-weight: 500;
}

.provide-best-way-to-book-a-session-with-a-coach-you-like-without-even-calling-them-first-check-when-they-are-free-and-then-send-a-request-to-book-for-a-session-7QEvON {
    opacity: 1;
    position: relative;
    min-height: 72px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 72px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.block-woNqdT {
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 1224px;
    gap: 104px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frame-4-eBb9LK {
    opacity: 1;
    position: relative;
    min-height: 112px;
    width: 520px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.track-your-bookings-16cIvy {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 40px;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    font-weight: 500;
}

.and-finally---easily-sign-up-and-log-in-to-track-all-the-sports-sessions-youve-booked-in-one-place-over-time-16cIvy {
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 520px;
    color: rgba(17.00000088661909, 28.000000230968, 24.000000469386578, 1);
    height: 48px;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.mockuuups-holding-samsung-s20-mockup-in-front-of-a-bike-eBb9LK {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    opacity: 1;
    position: relative;
    min-height: 408px;
    width: 600px;
}

.footer--hlgf4F {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 479px;
    width: 100%;
    gap: 64px;
    margin: 0 auto;
    overflow: hidden;
    padding-left: 84px;
    padding-right: 84px;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(13.007443025708199, 2.589751034975052, 20.10034192353487, 1);
}

.content-wYjOk4 {
    opacity: 1;
    position: relative;
    min-height: 200px;
    width: 1272px;
    gap: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.xіі--L1miAN {
    opacity: 1;
    position: relative;
    min-height: 116px;
    width: 313px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.logo-Jc20Wv {
    opacity: 1;
    position: relative;
    min-height: 48px;
    width: 96px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 48px;
    font-size: 40px;
    line-height: 46.8px;
    text-align: left;
    font-weight: 500;
}

.lorem-ipsum
is-simply-dummy-text-of-the-printing-and-typesetting-industry-Jc20Wv {
    opacity: 1;
    position: relative;
    min-height: 44px;
    width: 313px;
    color: rgba(255, 255, 255, 1);
    height: 44px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.column-L1miAN {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 200px;
    width: 133px;
    gap: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.navigation-UO7YH9 {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 133px;
    color: rgba(255, 255, 255, 1);
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
}

.footer-links-UO7YH9 {
    opacity: 1;
    position: relative;
    min-height: 152px;
    width: 133px;
    display: flex;
    flex-direction: column;
}

.link-VTWYeP {
    opacity: 1;
    position: relative;
    min-height: 38px;
    width: 133px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
}

.home-dUfDrQ {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 133px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.link-wVewbE {
    opacity: 1;
    position: relative;
    min-height: 38px;
    width: 133px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
}

.about-us-KjCUrW {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 133px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.link-FBWrZU {
    opacity: 1;
    position: relative;
    min-height: 38px;
    width: 133px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
}

.coaches-dWv3BH {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 133px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.link-QDf1qA {
    opacity: 1;
    position: relative;
    min-height: 38px;
    width: 133px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
}

.my-account-8WR7wx {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 133px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.column-HCP5eq {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 168px;
    width: 143px;
    gap: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.social-media-2vqZ2n {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 143px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
}

.footer-links-2vqZ2n {
    opacity: 1;
    position: relative;
    min-height: 120px;
    width: 143px;
    display: flex;
    flex-direction: column;
}

.link-vAKeAB {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 143px;
    gap: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.instagram-xKUELU {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 24px;
    overflow: hidden;
}

.instagram-BsNnIH {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 107px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.link-M2h0Me {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 143px;
    gap: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.x-xTCtRr {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 24px;
    overflow: hidden;
}

.x-LI1jAy {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 107px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.link-YGDNEN {
    opacity: 1;
    position: relative;
    min-height: 40px;
    width: 143px;
    gap: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frame-CEiRt3 {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 24px;
    overflow: hidden;
}

.facebook-CEiRt3 {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 107px;
    color: rgba(255, 255, 255, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.form-L1miAN {
    opacity: 1;
    position: relative;
    min-height: 178px;
    width: 340px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.text-lc5Ayq {
    opacity: 1;
    position: relative;
    min-height: 66px;
    width: 340px;
    gap: 12px;
    display: flex;
    flex-direction: column;
}

.newsletter-jvmazO {
    opacity: 1;
    position: relative;
    min-height: 32px;
    width: 340px;
    color: rgba(255, 255, 255, 1);
    height: 32px;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
}

.be-the-first-to-know-about-our-sales-new-products-and-special-offers-jvmazO {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 340px;
    color: rgba(181.60675585269928, 182.70907670259476, 185.74243873357773, 1);
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.frame-96-lc5Ayq {
    opacity: 1;
    position: relative;
    min-height: 88px;
    width: 340px;
    gap: 12px;
    display: flex;
    flex-direction: row;
}

.input-YxJyad {
    opacity: 1;
    position: relative;
    min-height: 88px;
    width: 272px;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.email-address-sevSpm {
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 272px;
    color: rgba(255, 255, 255, 1);
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.input-sevSpm {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    opacity: 1;
    position: relative;
    min-height: 56px;
    width: 272px;
    gap: 8px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    border-width: 1px;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(203.63388508558273, 203.84761601686478, 204.43579405546188, 1);
}

.enter-your-email-address-RyrmG1 {
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 224px;
    color: rgba(149.90795999765396, 149.90795999765396, 149.90795999765396, 1);
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
}

.button-YxJyad {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    opacity: 1;
    position: relative;
    min-height: 56px;
    width: 56px;
    gap: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
}

.frame-PjhrFA {
    overflow: hidden;
    opacity: 1;
    position: relative;
    min-height: 24px;
    width: 24px;
    overflow: hidden;
}

.credits-wYjOk4 {
    opacity: 1;
    position: relative;
    min-height: 55px;
    width: 1272px;
    gap: 32px;
    display: flex;
    flex-direction: column;
}

.divider-lDfZi0 {
    opacity: 1;
    position: relative;
    min-height: 1px;
    width: 1272px;
    background-color: rgba(68.38183745741844, 68.38183745741844, 68.38183745741844, 1);
}

.row-lDfZi0 {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 1272px;
    gap: 64px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.x-2024-spotya-AGU0kh {
    opacity: 1;
    position: relative;
    min-height: 22px;
    width: 96px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
}

.footer-links-AGU0kh {
    opacity: 1;
    position: relative;
    min-height: 21px;
    width: 216px;
    gap: 24px;
    display: flex;
    flex-direction: row;
}

.privacy-policy-Diw57H {
    opacity: 1;
    position: relative;
    min-height: 21px;
    width: 87px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 21px;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
}

.terms-of-service-Diw57H {
    opacity: 1;
    position: relative;
    min-height: 21px;
    width: 105px;
    color: rgba(255, 255, 255, 1);
    white-space: pre;
    height: 21px;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
}

.overflow {
    overflow: hidden;
}

.hero-page-lbYjbP {
    border-radius: 24px;
    width: 100%;
}

.hero-header {
    font-family: Karla, serif;
    font-size: 70px;
    font-weight: 400;
    line-height: 84.17px;
    text-align: left;
    color: white;

    margin-top: -36px;
}

.hero-hlgf4F p.text-white {
    font-weight: 400;
    font-size: 18px !important;
    color: white;
}

.footer--hlgf4F {
    display: none !important;
}

.hero-hlgf4F p.text-white {
    font-weight: 400;
    font-size: 18px !important;
    color: white;
}

.content-Y0rnOi p {
    padding: 0 !important;
}
